import React from "react";
import alualu from "../Images/Infra_images/alualu.jpg";
import office from "../Images/Infra_images/office.jpg";

function AboutUs() {
  return (
    <>
      <h2 className="pageHeadingAbout headerOfPage"></h2>

      <div className="container d-flex flex-wrap justify-content-center aboutUsPageScreen mt-4 mb-5">

        <div className="aboutUsPageScreen3 aboutUsPageScreen2">
          <div className="container p-3">
            <div className="justify-content-center">
              <h2 className="headerOfPage">About Ekanthika Pharmaceticals</h2>
              <p className="aboutUsP borderClass">
                Avail from us highest quality pharmaceuticals Tablets, Capsules,
                Oral Suspensions and variety of other range products.
              </p>
            </div>
            <hr />
            <p className="aboutUsP borderClass">
              Popular as an eminent manufacturer, we, Ekanthika Pharmaceuticals
              are regularly growing all over India. The wide array offered by us
              comprises of pharmaceuticals Tablets & Capsules of Beta-Lactum &
              Non-Beta Lactum both, Oral Suspensions of Beta-Lactum and variety
              of other range products . Customers highly admire us for our
              incomparable quality standards as well as affordable prices. By
              the help of our huge supply channel, we have been associated with
              numerous clients. Our vision is to make our company become the
              leader of this vertical. We have been getting great response from
              all the major markets. All our business operations are managed by
              experienced personnel. We have divided them into various teams
              like production, quality control, packaging and storage etc.. The
              responsibility of quick and safe shipment is given to diligent
              executives. There is a separate team which is available to respond
              the queries of customers. For us, our very first priority is their
              satisfaction and happiness. We earn that, our teams go beyond
              normal limits and deliver excellence along with solutions and
              services. We just require a chance and it is always turned into
              the best decision.
            </p>
            <hr />
            <h5>Why Us?</h5>
            <ul className="borderClass">
              <li>
                <p className="aboutUsP">
                  We have been able to complete big orders in less time owing to
                  our large manufacturing capacity{" "}
                </p>
              </li>
              <li>
                <p className="aboutUsP">
                  Our medicines are manufactured using the best quality
                  compounds and preferred for their accurate compositions
                </p>
              </li>
              <li>
                <p className="aboutUsP">
                  We make available a huge range of medicines at market leading
                  prices for the convenience of customers
                </p>
              </li>
              <li>
                <p className="aboutUsP">
                  Our team embraces transparent communications with clients and
                  operate the business with ethics
                </p>
              </li>
              <li>
                <p className="aboutUsP">
                  We give guarantee of timely and safe delivery of consignments{" "}
                </p>
              </li>
            </ul>
            <hr />
            <h5>Packaging of our Medicines</h5>
            <p className="aboutUsP borderClass">
              All our offerings such as pharmaceuticals Tablets, Capsules, Oral
              Suspensions and variety of other range products. are packed using
              A grade material. It keeps the medicines from moisture and prevent
              them from coming in contact with dirt. Whole of the packaging
              procedure takes place under the supervision of an expert team. Our
              department is equipped with modern equipment used to seal the
              packets accurately. After the process, consignments are checked by
              experts who make sure that there are no cuts and holes on packs.
            </p>
            <hr />
            <h5>Helping To Make People Healthier</h5>
            <p className="aboutUsP borderClass">
              Because of pollution, stress and common infections, health get
              affected regularly. To help our society, we have adopted advanced
              medical technology which is helpful to develop effective drugs.
              Our health supplements make the body healthier and immune towards
              infections.
            </p>
          </div>
        </div>

        <div className="aboutUsPageScreen4 aboutUsPageScreen2">
          <div className="container p-3">
            <div class="album py-3 bg-light " style={{ borderRadius: "20px" }}>
              <h3 className="headerOfPage">Our Infrastructure</h3>
              <div class="container">
                <div class="row row-cols-1 row-cols-sm-1 g-3">
                  <div class="col">
                    <div class="card shadow-sm">
                      {/* <img
                        src={office}
                        class="rounded"
                        alt="image not available now"
                      /> */}
                    </div>
                  </div>
                  <div class="col">
                    <div class="card shadow-sm">
                      {/* <img
                        src={alualu}
                        class="rounded"
                        alt="image not available now"
                      /> */}
                    </div>
                  </div>
                  {/* <div class="col">
                    <div class="card shadow-sm">
                      <img
                        src={Sigaspore}
                        class="rounded"
                        alt="image not available now"
                      />
                      <div class="text-center">
                        <h5>Sigaspore 50 Capsules</h5>
                      </div>
                      <div class="card-body">
                        <p class="card-text"></p>
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="card shadow-sm">
                      <img
                        src={savcidL}
                        class="rounded"
                        alt="image not available now"
                      />
                      <div class="text-center">
                        <h5>SAVCID-L Capsules</h5>
                      </div>
                      <div class="card-body">
                        <p class="card-text"></p>
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="card shadow-sm">
                      <img
                        src={savcidDsr}
                        class="rounded"
                        alt="image not available now"
                      />
                      <div class="text-center">
                        <h5>SAVCID DSR Capsules</h5>
                      </div>
                      <div class="card-body">
                        <p class="card-text"></p>
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="card shadow-sm">
                      <img
                        src={kkClinda}
                        class="rounded"
                        alt="image not available now"
                      />
                      <div class="text-center">
                        <h5>KK-CLINDA Capsules</h5>
                      </div>
                      <div class="card-body">
                        <p class="card-text"></p>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </>
  );
}

export default AboutUs;
