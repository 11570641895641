import Carousel from "react-bootstrap/Carousel";
import Banner1 from "../Images/Banner/Capsule_Banner.jpeg";
import Banner2 from "../Images/Banner/doctor_banner.jpeg";
import Banner3 from "../Images/Banner/hand_banner.jpeg";

function Banner() {
  return (
    <Carousel>
      <Carousel.Item>
        <img className="d-block w-100" style={{height:'100%'}} src={Banner2} alt="First slide" />
        <Carousel.Caption>
          <h3>Welcome to Ekanthika Pharmaceuticals</h3>
          <p>We care for all your Pharmaceuticals requirements</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img className="d-block w-100" style={{height:'100%'}} src={Banner1} alt="Second slide" />

        <Carousel.Caption>
          <h3>Welcome to Ekanthika Pharmaceuticals</h3>
          <p>We care for all your Pharmaceuticals requirements</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img className="d-block w-100" style={{height:'100%'}} src={Banner3} alt="Third slide" />

        <Carousel.Caption>
          <h3>Welcome to Ekanthika Pharmaceuticals</h3>
          <p>We care for all your Pharmaceuticals requirements</p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
}

export default Banner;
