import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import React, { useRef } from "react";
import emailjs from "@emailjs/browser";

function ContactUs() {
  const form = useRef();

  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_n7j1ol8",
        "template_0k8bxas",
        form.current,
        "eRjaLsUIAyKF_2YAC"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
    alert("Your query has been sent to the company");
  };

  return (
    <>
      <div className="container">
        
        <div className="contactUsPageMap contactUsPageMapContainer ">
          <div className="contactUsPageMapInner contactUsPageMapInnerMedia">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3422.6007744924245!2d76.84322231513613!3d30.92578138157021!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390f8ab51ca7aaab%3A0xf6eca90499af00af!2sEkanthika%20Pharmaceuticals!5e0!3m2!1sen!2sin!4v1690619829825!5m2!1sen!2sin"
              width="100%"
              height="100%"
              style={{ borderRadius: "20px" }}
              allowFullScreen=""
              className="contactUsPageMapInnerMost"
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>

          <div className="contactUsPageMapInnerForm contactUsPageMapInnerMedia">
          <div
            className="container pt-2 pb-3 mt-5 mb-5 bg-dark"
            style={{ maxWidth: "400px", borderRadius: "20px" }}
          >
            <h2 className="pageHeading">Contact US</h2>
            <Form ref={form} onSubmit={handleSubmit}>
              <div style={{ textAlign: "left" }}>
                <Form.Group className="mb-3" controlId="formBasicName">
                  <Form.Label style={{ color: "white" }}>Full Name</Form.Label>
                  <Form.Control type="text" name="from_name" />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label style={{ color: "white" }}>
                    Your Email ID
                  </Form.Label>
                  <Form.Control type="email" name="email_id" />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicName">
                  <Form.Label style={{ color: "white" }}>
                    Your Address
                  </Form.Label>
                  <Form.Control type="text" name="address" />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicName">
                  <Form.Label style={{ color: "white" }}>Your Query</Form.Label>
                  <Form.Control type="text" name="message" />
                </Form.Group>
              </div>
              <div>
                <Button
                  variant="dark"
                  type="submit"
                  value="Send"
                  style={{
                    border: "2px solid white",
                    borderRadius: "15px",
                    width: "120px",
                  }}
                >
                  Submit
                </Button>
              </div>
            </Form>
          </div>
        </div>
        </div>

      
      </div>
    </>
  );
}

export default ContactUs;
