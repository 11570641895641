import React from 'react';

function BestSellingProducts() {
    return (
        <div className='conatiner d-flex justify-content-center align-items-center col-12' style={{width:'100%',height:'62vh'}}>
            <h2>The page is coming soon...</h2>
        </div>
    );
}

export default BestSellingProducts;