import React from "react";
import Accordion from "react-bootstrap/Accordion";
import kkWhey from "../Images/Powder/kkWhey.jpg";
import Sigaspore from "../Images/Capsules/Sigaspore_50.jpeg";
import kMoxclave from "../Images/OralSuspension/kMoxclave.jpg";
import kkClinda from "../Images/Capsules/kkClinda.jpg";
import emraldL from "../Images/Capsules/emraldL.jpg";
import kkIt200 from "../Images/Capsules/kkIt200.jpg";
import savcidDsr from "../Images/Capsules/savcidDsr.jpg";
import savcidL from "../Images/Capsules/savcidL.jpg";
import kkAlpha from "../Images/Tablets/kkAlpha.jpg";
import kkBolB6 from "../Images/Tablets/kkBolB6.jpg";
import kkCefro250 from "../Images/Tablets/kkCefro250.jpg";
import kkGaba450 from "../Images/Tablets/kkGaba450.jpg";
import kkIverDt from "../Images/Tablets/kkIverDt.jpg";
import kkLinzyde from "../Images/Tablets/kkLinzyde.jpg";
import kkLyco from "../Images/Tablets/kkLyco.jpg";
import kkSol4 from "../Images/Tablets/kkSol4.jpg";
import savcidAz500 from "../Images/Tablets/savcidAz500.jpg";
import savcidC from "../Images/Tablets/savcidC.jpg";
import savcidcefDt200 from "../Images/Tablets/savcidcefDt200.jpg";
import savcidCv625 from "../Images/Tablets/savcidCv625.jpg";
import savcidoxim200 from "../Images/Tablets/savcidoxim200.jpg";
import savcidPlus from "../Images/Tablets/savcidPlus.jpg";
import savcidS400 from "../Images/Tablets/savcidS400.jpg";
import savcidSam from "../Images/Tablets/savcidSam.jpg";
import savcidSp from "../Images/Tablets/savcidSp.jpg";
import savcon600 from "../Images/Tablets/savcon600.jpg";

function Gallery() {
  return (
    <div className="container bg-dark pt-2 pb-2 mb-4 mt-4 backgroundImg">
      <h2 className="pageHeading">Product Images Gallery</h2>

      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>Tablet's Products Gallery</Accordion.Header>
          <Accordion.Body>
            <div>
              <div class="album py-5 bg-light">
                <div class="container">
                  <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
                    <div class="col">
                      <div class="card shadow-sm">
                        <img
                          src={kkAlpha}
                          class="rounded"
                          alt="image not available now"
                        />
                        <div class="text-center">
                          <h5>KK ALPHA Tablets</h5>
                        </div>
                      </div>
                    </div>
                    <div class="col">
                      <div class="card shadow-sm">
                        <img
                          src={kkBolB6}
                          class="rounded"
                          alt="image not available now"
                        />
                        <div class="text-center">
                          <h5>KK-BOL 6 Tablets</h5>
                        </div>
                      </div>
                    </div>
                    <div class="col">
                      <div class="card shadow-sm">
                        <img
                          src={kkCefro250}
                          class="rounded"
                          alt="image not available now"
                        />
                        <div class="text-center">
                          <h5>KK-CEFRO 250 Tablets</h5>
                        </div>
                      </div>
                    </div>
                    <div class="col">
                      <div class="card shadow-sm">
                        <img
                          src={savcon600}
                          class="rounded"
                          alt="image not available now"
                        />
                        <div class="text-center">
                          <h5>SAVCON 600 Tablets</h5>
                        </div>
                      </div>
                    </div>
                    <div class="col">
                      <div class="card shadow-sm">
                        <img
                          src={kkGaba450}
                          class="rounded"
                          alt="image not available now"
                        />
                        <div class="text-center">
                          <h5>KK-GABA 450 Tablets</h5>
                        </div>
                      </div>
                    </div>
                    <div class="col">
                      <div class="card shadow-sm">
                        <img
                          src={kkIverDt}
                          class="rounded"
                          alt="image not available now"
                        />
                        <div class="text-center">
                          <h5>KK-IVER DT Tablets</h5>
                        </div>
                      </div>
                    </div>

                    <div class="col">
                      <div class="card shadow-sm">
                        <img
                          src={kkLinzyde}
                          class="rounded"
                          alt="image not available now"
                        />
                        <div class="text-center">
                          <h5>KK-LINZYDE Tablets</h5>
                        </div>
                      </div>
                    </div>
                    <div class="col">
                      <div class="card shadow-sm">
                        <img
                          src={kkLyco}
                          class="rounded"
                          alt="image not available now"
                        />
                        <div class="text-center">
                          <h5>KK-LYCO Tablets</h5>
                        </div>
                      </div>
                    </div>
                    <div class="col">
                      <div class="card shadow-sm">
                        <img
                          src={kkSol4}
                          class="rounded"
                          alt="image not available now"
                        />
                        <div class="text-center">
                          <h5>KKSOL 4 Tablets</h5>
                        </div>
                      </div>
                    </div>

                    <div class="col">
                      <div class="card shadow-sm">
                        <img
                          src={savcidAz500}
                          class="rounded"
                          alt="image not available now"
                        />
                        <div class="text-center">
                          <h5>SAVCID AZ 500 Tablets</h5>
                        </div>
                      </div>
                    </div>
                    <div class="col">
                      <div class="card shadow-sm">
                        <img
                          src={savcidC}
                          class="rounded"
                          alt="image not available now"
                        />
                        <div class="text-center">
                          <h5>SAVCID C Tablets</h5>
                        </div>
                      </div>
                    </div>
                    <div class="col">
                      <div class="card shadow-sm">
                        <img
                          src={savcidcefDt200}
                          class="rounded"
                          alt="image not available now"
                        />
                        <div class="text-center">
                          <h5>SAVCIDCEF DT 200 Tablets</h5>
                        </div>
                      </div>
                    </div>
                    <div class="col">
                      <div class="card shadow-sm">
                        <img
                          src={savcidCv625}
                          class="rounded"
                          alt="image not available now"
                        />
                        <div class="text-center">
                          <h5>SAVCID CV 625 Tablets</h5>
                        </div>
                      </div>
                    </div>
                    <div class="col">
                      <div class="card shadow-sm">
                        <img
                          src={savcidoxim200}
                          class="rounded"
                          alt="image not available now"
                        />
                        <div class="text-center">
                          <h5>SAVCIDOXIM 200 Tablets</h5>
                        </div>
                      </div>
                    </div>
                    <div class="col">
                      <div class="card shadow-sm">
                        <img
                          src={savcidPlus}
                          class="rounded"
                          alt="image not available now"
                        />
                        <div class="text-center">
                          <h5>SAVCID-PLUS Tablets</h5>
                        </div>
                      </div>
                    </div>

                    <div class="col">
                      <div class="card shadow-sm">
                        <img
                          src={savcidS400}
                          class="rounded"
                          alt="image not available now"
                        />
                        <div class="text-center">
                          <h5>SAVCID S400 Tablets</h5>
                        </div>
                      </div>
                    </div>
                    <div class="col">
                      <div class="card shadow-sm">
                        <img
                          src={savcidSam}
                          class="rounded"
                          alt="image not available now"
                        />
                        <div class="text-center">
                          <h5>SAVCIDSAM Tablets</h5>
                        </div>
                      </div>
                    </div>
                    <div class="col">
                      <div class="card shadow-sm">
                        <img
                          src={savcidSp}
                          class="rounded"
                          alt="image not available now"
                        />
                        <div class="text-center">
                          <h5>SAVCID-SP Tablets</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header> Capsule's Products Gallery</Accordion.Header>
          <Accordion.Body>
            <div>
              <div class="album py-5 bg-light">
                <div class="container">
                  <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
                    <div class="col">
                      <div class="card shadow-sm">
                        <img
                          src={emraldL}
                          class="rounded"
                          alt="image not available now"
                        />
                        <div class="text-center">
                          <h5>EMRALD-L Capsules</h5>
                        </div>
                      </div>
                    </div>
                    <div class="col">
                      <div class="card shadow-sm">
                        <img
                          src={kkIt200}
                          class="rounded"
                          alt="image not available now"
                        />
                        <div class="text-center">
                          <h5>KKIt 200 Capsules</h5>
                        </div>
                      </div>
                    </div>
                    <div class="col">
                      <div class="card shadow-sm">
                        <img
                          src={Sigaspore}
                          class="rounded"
                          alt="image not available now"
                        />
                        <div class="text-center">
                          <h5>Sigaspore 50 Capsules</h5>
                        </div>
                      </div>
                    </div>
                    <div class="col">
                      <div class="card shadow-sm">
                        <img
                          src={savcidL}
                          class="rounded"
                          alt="image not available now"
                        />
                        <div class="text-center">
                          <h5>SAVCID-L Capsules</h5>
                        </div>
                      </div>
                    </div>
                    <div class="col">
                      <div class="card shadow-sm">
                        <img
                          src={savcidDsr}
                          class="rounded"
                          alt="image not available now"
                        />
                        <div class="text-center">
                          <h5>SAVCID DSR Capsules</h5>
                        </div>
                      </div>
                    </div>
                    <div class="col">
                      <div class="card shadow-sm">
                        <img
                          src={kkClinda}
                          class="rounded"
                          alt="image not available now"
                        />
                        <div class="text-center">
                          <h5>KK-CLINDA Capsules</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>Oral Suspension Products Gallery</Accordion.Header>
          <Accordion.Body>
            <div>
              <div class="album py-5 bg-light">
                <div class="container">
                  <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
                    <div class="col">
                      <div class="card shadow-sm">
                        <img
                          src={kMoxclave}
                          class="rounded"
                          alt="image not available now"
                        />
                        <div class="text-center">
                          <h5>K-Moxclav Oral Suspension</h5>
                        </div>
                        <div class="card-body"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header>Powder Products Gallery</Accordion.Header>
          <Accordion.Body>
            <div>
              <div class="album py-5 bg-light">
                <div class="container">
                  <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
                    <div class="col">
                      <div class="card shadow-sm">
                        <img
                          src={kkWhey}
                          class="rounded"
                          alt="image not available now"
                        />
                        <div class="text-center">
                          <h5>KKWHEY Powder</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
}

export default Gallery;
