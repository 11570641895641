import React from "react";
import { RxArrowRight } from "react-icons/rx";
import { MdLocationOn } from "react-icons/md";
import { AiOutlineContacts } from "react-icons/ai";
import { AiFillPhone } from "react-icons/ai";
import { AiOutlineMail } from "react-icons/ai";

function FooterEk() {
  return (
    <>
      <div className="container-fluid bg-dark">

        <div className="row justify-content-evenly footerSmScreen">

          <div className="col mt-3 mb-3 d-flex justify-content-center align-items-center">
            <div className="d-flex ">
            <ul className="text-start p-7 m-1">
              <li>
                <a href="/" className="footerColorAnchor">
                  <RxArrowRight />
                  Home
                </a>
              </li>
              <li>
                <a href="AboutUs" className="footerColorAnchor">
                  <RxArrowRight />
                  About Us
                </a>
              </li>
              <li>
                <a href="Tablets" className="footerColorAnchor">
                  <RxArrowRight />
                  Products
                </a>
              </li>
              <li>
                <a href="Gallery" className="footerColorAnchor">
                  <RxArrowRight />
                  Gallery
                </a>
              </li>
              <li>
                <a href="ContactUs" className="footerColorAnchor">
                  <RxArrowRight />
                  Contact Us
                </a>
              </li>
            </ul>
            </div>
          
          </div>

          <div className="col me-3 footerAddress text-start d-flex align-items-center">
            <p>
              <MdLocationOn fontSize="1em" /> Office Address{" "}
              <br /> Plot No. 2/4, EPIP Phase-1 Jharmajri Baddi, Distt.-Solan HP
              174103
            </p>
          </div>
          
          <div className="col ms-3 footerAddress text-start d-flex align-items-center" >
            <p>
              <AiOutlineContacts fontSize="1em" /> Contact Details <br />
              <AiFillPhone fontSize="1em" /> +91 90152 74090 
              <br />
              <AiOutlineMail fontSize="1em" /> info@ekanthikapharmaceuticals.com 
            </p>
          </div>
        </div>
      </div>



      <div className="footerCopyright bg-dark mb-4 p-1">
        All Rights Reserved. @2023 Ekanthika Pharmaceuticals
      </div>
    </>
  );
}

export default FooterEk;
