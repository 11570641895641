import Button from "react-bootstrap/Button";
import React, { useEffect, useState } from "react";

function simulateGnNetworkRequest() {
  return new Promise((resolve) => setTimeout(resolve, 1000));
}

function simulateBtNetworkRequest() {
  return new Promise((resolve) => setTimeout(resolve, 1000));
}

function DownloadProductList() {
  const [isLoadingGeneral, setLoadingGeneral] = useState(false);
  const [isLoadingBeta, setLoadingBeta] = useState(false);

  useEffect(() => {
    if (isLoadingGeneral) {
      simulateGnNetworkRequest().then(() => {
        setLoadingGeneral(false);
      });
    }
    if (isLoadingBeta) {
      simulateBtNetworkRequest().then(() => {
        setLoadingBeta(false);
      });
    }
  }, [isLoadingGeneral, isLoadingBeta]);

  const handleGeneralClick = () => {
    setLoadingGeneral(true);
  };
  const handleBetaClick = () => setLoadingBeta(true);

  return (
    <>
      <div className="DwonloadProductlist">
        <Button
          variant="primary"
          size="lg"
          className="DownloadGnPdListItem"
          href="https://drive.google.com/u/0/uc?id=1iR0fEcZwQxwXxhfZ4kYL7umLdul2KicJ&export=download"
          onClick={!isLoadingGeneral ? handleGeneralClick : null}
        >
          <h3>
            {" "}
            {isLoadingGeneral
              ? "Downloading please wait…"
              : "Click here to download General Section Products List"}{" "}
          </h3>
        </Button>
        <Button
          variant="primary"
          size="lg"
          className="DownloadBtPdListItem"
          href="https://drive.google.com/u/0/uc?id=1CIztiQN6rV9IKQ6pw40Awrmk5nfuUzab&export=download"
          onClick={!isLoadingBeta ? handleBetaClick : null}
        >
          <div className="TextInCenter">
            <h3>
              {" "}
              {isLoadingBeta
                ? "Downloading please wait…"
                : "Click here to download Beta Section Products List"}{" "}
            </h3>
          </div>
        </Button>
      </div>
    </>
  );
}

export default DownloadProductList;
