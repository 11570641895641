import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";

function Navig() {
  return (
    <Navbar className="mb-1 sticky-top" bg="dark" variant="dark" expand="lg">
      <Container>
        <Navbar.Brand href="/">Ekanthika Pharmaceuticals</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="me-auto">
          <Nav.Link className="ms-5" href="/">
            Home
          </Nav.Link>
          <Nav.Link className="ms-5" href="AboutUs">
            About Us
          </Nav.Link>
          <NavDropdown
            className="ms-5"
            title="Product List"
            id="basic-nav-dropdown"
          >
            <NavDropdown.Item href="Tablets">Tablets</NavDropdown.Item>
            <NavDropdown.Item href="Capsules">Capsules</NavDropdown.Item>
            <NavDropdown.Item href="OralSuspension">
              Oral Suspension
            </NavDropdown.Item>
            {/* <NavDropdown.Divider />
            <NavDropdown.Item href="HotProducts">Hot Products</NavDropdown.Item>
            <NavDropdown.Item href="BestSellingProducts">
              Best Selling Products
            </NavDropdown.Item> */}
          </NavDropdown>
          <Nav.Link className="ms-5" href="Gallery">
            Gallery
          </Nav.Link>
          <Nav.Link className="ms-5" href="ContactUs">
            Contact Us
          </Nav.Link>
          <Nav.Link className="ms-5" href="DownloadProductList">
            Download Our Product List
          </Nav.Link>
        </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Navig;
