import React from "react";
import Banner from "./Banner";
import eklogo from "../Images/eklogo.jpg";
import "../CustumCss/CustumCss.css";
import visionlogo from "../Images/vision.jpeg";
import valueslogo from "../Images/values_logo.png";
import missionlogo from "../Images/mission_logo.jpg";
import qualityBanner from "../Images/qualityBanner.png";
import services from "../Images/services.jpg";

function Home(props) {
  return (
    <div>
      <Banner />
      <div className="container">
        <img src={eklogo} alt="ekanthika company logo" />
      </div>
      <div className="container">
        <span>
          <h3>
            We at{" "}
            <i>
              <b style={{ color: "dark" }}>EKANTHIKA PHARMACEUTICALS</b>
            </i>
          </h3>
        </span>
        <span>
          <p style={{ fontSize: "20px" }}>
            Care for all your Pharmaceuticals requirements
          </p>
        </span>
      </div>

      <div className="container align-items-center  mt-5 mb-5 text-center">
        <p className="aboutUsP borderClass">
          Having our huge premises in Baddi, Himachal Pradesh (India), we,
          Ekanthika Pharmaceuticals are a trusted company for Pharmaceuticals
          requirements. Our offered line comprises of pharmaceuticals Tablets,
          Capsules, Oral Suspensions and variety of other range products. The
          range is high in quality as well as offered at highly reasonable
          prices. We understand our responsibility and role being a part of the
          pharmaceuticals domain. Thus, our team gives its best to deliver
          greatly effective offerings. We aim to grow more in this vertical and
          acquire the top most position. The current feedback from markets show
          that our company is very near to achieve this dream.
        </p>
      </div>
      <div className="container align-items-center mt-3 mb-3 text-center col-4">
        <hr style={{ color: "red" }} />
      </div>

      <div className="container mt-5 mb-5 ">
        <div className="row">
          <div className="col-sm-8 offset-md-2 text-center">
            <h3 style={{ marginBottom: "10px" }}>
              What's makes us <br /> EKANTHIKA PHARMACEUTICALS{" "}
            </h3>
          </div>

          <div className="col-sm-4 mt-5 ">
            <div className="choosediv mt-3">
              <span className="mt-5 mb-3">
                <img src={visionlogo} alt="visionlogo" />
              </span>
              <h6 className="mt-2">Our VISION</h6>
              <p className="aboutUsP">
                To serve the ailing human beings with quality medicines at
                affordable prices by the continuously upgrading systems,
                technology, knowledge for excellent productivity and quality.
              </p>
            </div>
          </div>
          <div className="col-sm-4 ">
            <div className="choosediv mt-5">
              <span>
                <img src={missionlogo} alt="missionlogo" />
              </span>
              <h6>Our Mission</h6>
              <p className="aboutUsP">
                To become a global healthcare organization and to be recognized
                as domestic and internationally integrated healthcare and
                pharmaceutical company through Innovation, Quality and
                Competence.
              </p>
            </div>
          </div>

          <div className="col-sm-4 mt-5 ">
            <div className="choosediv mt-4">
              <span>
                <img src={valueslogo} alt="valueslogo" />
              </span>
              <h6 className="mt-4">Our VALUE</h6>
              <p className="aboutUsP">
                We encourage productiveness, improvisation, and teamwork. We
                believe that there are no limits to what we can accomplish and
                are always willing to explore new products, markets, and
                territories.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container marketing mt-5">
        <hr className="featurette-divider mt-5 mb-5" />

        <div className="row featurette mt-5 d-flex align-items-center">
          <div className="col-md-7 mt-4">
            <h2 className="featurette-heading fw-normal lh-1">
              Think for QUALITY! &nbsp; &nbsp;
              <span className="text-muted">Think for us.</span>
            </h2>
            <p className="lead aboutParagraphP borderClass">
              We believe that the quality of a pharmaceutical product is
              determined by the quality of the raw materials, equipment, and the
              technical knowledge required to process, package, and to
              distribute the product, that's what we consider most at our plant.
            </p>
          </div>
          <div className="col-md-5">
            <img
              src={qualityBanner}
              className="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto"
              preserveAspectRatio="xMidYMid slice"
              alt="quality banner"
            />
          </div>
        </div>
        <hr className="featurette-divider mt-5 mb-5" />

        <div className="row featurette mt-5 d-flex align-items-center">
          <div className="col-md-7 order-md-2 ">
            <h2 className="featurette-heading fw-normal lh-1">
              Think for SERVICE! &nbsp; &nbsp;
              <span className="text-muted">Think for us.</span>
            </h2>
            <p className="lead aboutParagraphP borderClass">
              We believe that Service excellence is the act of going beyond
              customer expectations and delivering an industry-leading
              experience, this is the mantra that makes us special. .
            </p>
          </div>
          <div className="col-md-5 order-md-1">
            <img
              src={services}
              className="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto"
              preserveAspectRatio="xMidYMid slice"
              alt="services banner"
            />
          </div>
        </div>
        <hr className="featurette-divider mt-5 mb-5" />
      </div>
    </div>
  );
}

export default Home;
