import { Route, Routes } from "react-router";
import "./App.css";
import AboutUs from "./Components/AboutUs";
import ContactUs from "./Components/ContactUs";
import "./CustumCss/CustumCss.css";
import Gallery from "./Components/Gallery";
import Home from "./Components/Home";
import BestSellingProducts from "./Components/Products/BestSellingProducts";
import Capsules from "./Components/Products/Capsules";
import HotProducts from "./Components/Products/HotProducts";
import OralSuspension from "./Components/Products/OralSuspension";
import Tablets from "./Components/Products/Tablets";
import Navig from "./Components/Navig";
import FooterEk from "./Components/FooterEk";
import DownloadProductList from './Components/DownloadProductList'

function App() {
  return (
    <div className="App">
      <Navig />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/aboutUs" element={<AboutUs />} />
        <Route path="/tablets" element={<Tablets />} />
        <Route path="/capsules" element={<Capsules />} />
        <Route path="/oralSuspension" element={<OralSuspension />} />
        <Route path="/hotProducts" element={<HotProducts />} />
        <Route path="/bestSellingProducts" element={<BestSellingProducts />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/contactUs" element={<ContactUs />} />
        <Route path="/DownloadProductList" element={<DownloadProductList />} />
      </Routes>
      <FooterEk />
    </div>
  );
}

export default App;
