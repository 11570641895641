import React from "react";
import Table from "react-bootstrap/Table";
import TabletProductsName from "../../ProductList/Tablets.json";

function Tablets() {
  return (
    <>
      <h2 className="productPageHeading">Tablets Product List</h2>
      <div className="container">
        <Table striped bordered hover>
          <thead >
            <tr >
              <th style={{backgroundColor:'black' , color:'white' , fontWeight:'bold'}}>S No.</th>
              <th style={{backgroundColor:'black' , color:'white' , fontWeight:'bold'}}>Composition</th>
              <th style={{backgroundColor:'black' , color:'white' , fontWeight:'bold'}}>DoseForm</th>
              <th style={{backgroundColor:'black' , color:'white' , fontWeight:'bold'}}>Packing</th>
            </tr>
          </thead>
          <tbody>
            {TabletProductsName.map((record) => {
              return (
                <>
                  <tr key={record.Sno}>
                    <td>{record.Sno}</td>
                    <td>{record.Composition}</td>
                    <td>{record.DoseForm}</td>
                    <td>{record.Packing}</td>
                  </tr>
                </>
              );
            })}
          </tbody>
        </Table>
      </div>
    </>
  );
}

export default Tablets;
